<template>
    <mu-list class="phi-person-list" @personClicked="personClicked">
        <slot></slot>
    </mu-list>
</template>

<script>
export default {
    name: "phi-person-list",
    
    methods: {
        personClicked(personId){
            this.$emit("personClicked", personId);
        }
    }
}
</script>

<style lang="scss">
.phi-person-list{
    
}    
</style>
