    <template>
    <phi-page class="phi-transport-route-log page" color="#1C89B8">

        <div slot="toolbar" class="toolbar">
            <mu-icon-button @click="$router.go(-1)" icon="arrow_back" />
            <h1 style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
                <span v-if="route">{{ route.name }}</span>
            </h1>
            <div class="date-picker">
                <flatpickr v-model="date" :config="flatpickrConfig" icon="calendar_today"></flatpickr>
            </div>
        </div>

        <div slot="header" class="header"></div>

        <transition name="fade">
            <div class="phi-card drawer-routes" v-if="currentStop.length != 0">
                <phi-drawer :open="true">
                    <div @click="drawer =! drawer">
                        <div style="display: block;">
                            <div id="stop_icon" style="position: absolute; left: 25px; top: 10px;">
                                <mu-icon value="departure_board" :size="22" color="#0288d1"/>
                            </div>
                            <div id="stop_content" style="position: relative; padding-left: 40px;">
                                <h3 style="font-weight: 700; font-size:14px;" v-html="currentStop.name"></h3>
                                <small><p v-html="currentStop.address"></p></small>
                            </div>
                        </div>
                    </div>
                </phi-drawer>

                <phi-drawer :open="!drawer">
                    <div class="timeline">
                        <mu-timeline lineColor="#BDBDBD" lineType="dashed">
                            <mu-timeline-item
                                v-for="stop in stops.items"
                                :key="stop.id"
                                @click.native="currentStopChange(stop)"
                                :class="stop.id == currentStop.id ? 'mu-timeline-item-active' : ''"
                            >
                                <mu-icon value="directions" color="#0288D1" slot="icon"/>
                                <span slot="time" v-html="stop.name"></span>
                                <span slot="des"><small v-html="stop.address"></small></span>
                            </mu-timeline-item>
                        </mu-timeline>
                    </div>
                </phi-drawer>
            </div>
        </transition>

        <div class="phi-card stopLogs">
            <phi-node-log
                v-model="currentStopLog"
                :types="types.items"
                @delete="deleteLogEntry"
                @add="addLogEntry"
            />

            <div class="notify" v-if="$store.getters.getSetting('showTransportNotification') && showButtonNotify && nextStopPeople.length > 0">
                <post-notificator
                    v-if="route"
                    :title="`La ruta ${route.name} esta próxima en llegar`"
                    type="mensaje"
                    :description="`La ruta ${route.name} esta próxima a llegar a la parada de ${nextStop.name} con dirección ${nextStop.address}`"
                    :people="nextStopPeople"
                    :notificationParents="true"
                >
                    <template #button="{openNotifications}">
                        <button @click="openNotifications()">Notificar próxima parada</button>
                    </template>
                </post-notificator>
            </div>

        </div>

        <div slot="footer" class="footer">
            <div id="toogle-tracker" v-if="route.target">

                <transition name="fade">
                    <div id="options" v-if="tracker.isEnabled">
                        <mu-float-button
                            icon="navigation"
                            mini
                            @click="gotomap(route.target.identifier)"
                            backgroundColor="white"
                            class="float-button"
                            style="color: #0084F7;"
                        ></mu-float-button>

                        <mu-float-button
                            icon="location_off"
                            mini
                            @click="tracker.disable()"
                            backgroundColor="red"
                            class="float-button"
                        ></mu-float-button>
                    </div>

                    <div id="options" v-else>
                        <mu-float-button
                            icon="location_on"
                            mini
                            @click="tracker.enable()"
                            backgroundColor="white"
                            class="float-button"
                            style="color: #0084F7;"
                        ></mu-float-button>
                    </div>
                </transition>
            </div>
        </div>
    </phi-page>
</template>

<script>
import PhiPersonPicker from '../../../components/Phi/Person/Picker.vue';
import PhiNodeLog from '../../../components/Phi/Node/Event/Log.vue';
import PhiToggle from '../../../components/Phi/Toggle/Toggle.vue';

import PostNotificator from '@/modules/posts/components/Api/Notificator.vue';
import PersonItem from '@/modules/core/components/Person/ListItem.vue'

import Flatpickr from '../../../components/Flatpickr.vue';

export default {
    components: {PhiNodeLog, PhiPersonPicker, Flatpickr, PhiToggle, PostNotificator, PersonItem},

    data() {
        return {
            drawer: true,

            route: {},

            flatpickrConfig: {
                enableTime: false,
                dateFormat: 'D. M j'
            },

            stops: this.$store.state.api.collection(`/v3/transport/routes/${this.$route.params.routeId}/stops`),
            currentStop: '',

            nextStop: {},
            showButtonNotify: true,

            date: new Date,
            log: this.$store.state.api.collection(`/v3/transport/routes/${this.$route.params.routeId}/log`),
            types: this.$store.state.api.collection(`/types/checkpoint`),
            newRecord: {
                person: null,
                type: null
            }
        }
    },

    computed: {
        tracker() {
            return {
                isEnabled: this.$store.state.geo.tracker != null,
                enable: () => this.$store.dispatch("enable", this.route.target.id),
                disable: () => this.$store.dispatch("disable")
            }
        },

        nextStopPeople: function() {
            if (!this.nextStop) {
                return [];
            }

            let people = [];

            this.log.items.forEach(item => {
                if (item.stop == this.nextStop.id) {
                    people.push(item.person)
                }
            });

            return people
        },

        currentStopLog: function() {
            if (!this.currentStop) {
                return [];
            }

            return this.log.items.filter(item => item.stop == this.currentStop.id);
        }
    },

    methods: {
        currentStopChange(item) {
            this.loadNextStop(item.id); // llama ruta siguiente

            this.drawer = !this.drawer;
            return this.currentStop = item;
        },

        gotomap(route) {
            ///transport/routes/2/map
            this.$router.push({ name: 'transport-route-map', params: { routeId: route }});
        },

        reload() {
            let date = new Date(this.date);
            date.setHours(0,0,0,0);

            this.log.fetch({date: date.getTime()/1000, _nocache: Math.random()});
        },

        addLogEntry(entry) {
            if (!this.currentStop) {
                console.warn("No current stop is selected.  Cannot add log");
                return;
            }

            let date = new Date(this.date);
            date.setHours(0,0,0,0);
            entry.date = date.getTime()/1000;

            entry.stop = this.currentStop.id;

            this.$store.state.api
                .post(`/v3/transport/routes/${this.$route.params.routeId}/log`, entry)
                .then(createdEntry => this.log.unshift(createdEntry))
                .then(() => this.reload());
        },

        deleteLogEntry(entry) {
            this.$store.state.api
                .delete(`/v3/transport/routes/${this.$route.params.routeId}/log/${entry.id}`)
                .then(() => this.reload());
        },

        loadNextStop(currentStop) {
            if (currentStop) {
                let allStops = this.stops.items;
                let nextStop = {};

                for (var i = 0; i < allStops.length; i++) {
                    if (allStops[i].id == parseInt(currentStop)) {
                        nextStop = allStops[i+1] ? allStops[i+1] : {};
                    }
                }

                // Asigno detalles de la ruta siguiente
                this.nextStop = nextStop;

                // ocultar boton cuando no hay mas rutas
                this.showButtonNotify = this.nextStop.id ? true : false;
            }
        }
    },

    watch: {
        date() {
            this.reload();
        }
    },

    mounted() {
        this.$store.state.api
            .get(`/v3/transport/routes/${this.$route.params.routeId}/`)
            .then(response => this.route = response);

        this.stops.fetch().then(() => {
            if (this.stops.items.length > 0) {
                this.currentStop = this.stops.items[0];
                this.loadNextStop(this.currentStop.id); // llama ruta siguiente
            }
        });

        this.types.fetch().then(() => this.newRecord.type = this.types.items[0]);
        this.reload();
    }
}
</script>

<style lang="scss" scoped>
.phi-transport-route-log {
    background-color: #1C89B8 !important;

    .toolbar {
        color: #fff;

        h1 {
            color: #fff;
        }

        .date-picker > .component-flatpickr {
            display: flex;
            padding-right: 3px;
            align-items: center;
            font-size: 12px !important;
        }
    }

    .header {
        height: 40px;
        position: relative;

        .stop-picker {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;

            border: none;
            background-color: #1C89B8 !important;
            color: #fff;

            font-size: 1.2em;
            padding: 8px;
        }
    }


    .drawer-routes {
        top: -20px;
        padding: 14px;
        position: relative;
        border-right: 10px;
        border-radius: 6px;

        user-select: none;

        .phi-drawer {
            .title {
                color: #1C89B8;
            }

            span {
                opacity: .8;
                margin: 5px 0;
                font-size: 12px;
            }

            .timeline {
                padding: 10px;
                margin-top: 15px;
                border-top: 1px dashed #ccc;

                .mu-timeline-item {
                    width: 100%;
                    opacity: .6;
                    padding: 2px;
                    cursor: pointer;
                    transition: all .5s ease;

                    span {
                        font-size: 13px;
                    }
                }
                .mu-timeline-item-active {opacity: 1}
            }
        }
    }

}
.date-picker {
    padding-right: 18px;
}
.stopLogs{
    border-radius: 6px;
    top: -10px;
    position: relative;
}

#toogle-tracker {
    bottom: 0px;
    border-radius: 6px;
    position: absolute;
    width: 100%;
    z-index: 5;
}

#options {
    bottom: 10px;
    position: fixed;
    right: 15px;
    height: auto;
    width: 42px;
    z-index: 2;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
.float-button {
    margin-bottom: 10px;
}


.notify {
    width: 100%;
    padding: 10px;

    button {
        width: 100%;
        color: #fff;
        border: none;
        padding: 8px;
        font-size: 11px;
        border-radius: 4px;
        background: #2196F3;
        text-transform: uppercase;
    }
}

.phi-window .route-details {
    margin-bottom: 8px;

    .label {
        font-size: 12px;
        font-style: italic;
        margin-bottom: 4px;
    }

    .value {
        font-weight: bold;
    }

    ol {
        margin: 0;
        padding: 0;
        list-style: none;
    }
}
</style>
