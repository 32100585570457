<template>
    <mu-list-item
        class="phi-person-list-item"
        :class="{ selected: selected }"
        :style="{ paddingLeft: inset}"
        :disableRipple="true"
        :title="getPersonFullName(person)"
        :open="relativesOpened"
        @click="personClicked"
    >
        <phi-person-avatar
            :fliped="selected"
            :name="getPersonFullName(person)"
            :id="person.id"
            :image="person.avatar"
            :shape="avatarShape"
            slot="leftAvatar"
        >
            <div class="checked-avatar" slot="back">
                <mu-icon :size="30" value="check"/>
            </div>
        </phi-person-avatar>

        <div slot="describe"><slot name="describe"></slot></div>
        <div slot="after"><slot name="after"></slot></div>
        <div slot="right"><slot name="right"></slot></div>
        <div slot="nested"><slot name="nested"></slot></div>

        <mu-icon v-if="showRelativesToggler" :value="relativesOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down'" slot="right" @click="toggleRelatives"/>
    </mu-list-item>
</template>

<script>
import PhiPersonAvatar from './Avatar.vue';

export default {
    name: "phi-person-list-item",

    components: {PhiPersonAvatar},

    data () {
        return {
            hasRelatives: false,
            relativesOpened: null
        }
    },

    props: {
        person: {
            type: Object,
            required: true
        },

        avatarShape: {
            type: String,
            required: false,
            default: "square",
            validator: value => ["square", "circle"].indexOf(value) >= 0
        },

        selected: {
            type: Boolean,
            required: false,
            default: false
        },

        inset: {
            type: String,
            required: false,
            default: "0px"
        },

        showRelativesToggler: {
            type: Boolean,
            required: false,
            default: false
        },

        openedByDefault:{
            type: Boolean,
            required: false,
            default: false
        }
    },

    methods: {
        toggleRelatives() {
            this.relativesOpened = !this.relativesOpened;
        },

        personClicked(evt) {
            switch(this.getParentSlot(evt.target)) {
                case 'right':
                    this.$emit("secondaryAction", this.person.id);
                break;

                case 'left':
                case 'content':
                    this.$emit("primaryAction", this.person.id);

                    let parent = this.$parent;
                    while (parent && !parent.$el.classList.contains("phi-person-list")) {
                        parent = parent.$parent;
                    }

                    if (!parent) {
                        return;
                    }

                    parent.$emit("personClicked", this.person.id);
                break;
            }
        },

        getParentSlot(elem) {
            let slot = "content";
            let parent = elem;

            while(!parent.classList.contains("phi-person-list-item")) {
                if (parent.classList.contains("mu-item-left")) {
                    slot = "left";
                    break;
                }

                if (parent.classList.contains("mu-item-content")) {
                    slot = "content";
                    break;
                }

                if (parent.classList.contains("mu-item-right")) {
                    slot = "right";
                    break;
                }
                parent = parent.parentNode;
            }

            return slot;
        },

        getPersonFullName(person) {
            let fullName = "";

            if (person.hasOwnProperty("firstName") && person.firstName) {
                fullName = fullName.concat(person.firstName);

                if (person.hasOwnProperty("lastName") && person.lastName) {
                    fullName = fullName.concat(" ").concat(person.lastName);
                }

                if (person.hasOwnProperty("lastName2") && person.lastName2) {
                    fullName = fullName.concat(" ").concat(person.lastName2);
                }
            } else if (person.hasOwnProperty("firstname") && person.firstname) {
                fullName = fullName.concat(person.firstname);

                if (person.hasOwnProperty("lastname") && person.lastname) {
                    fullName = fullName.concat(" ").concat(person.lastname);
                }

                if (person.hasOwnProperty("lastname2") && person.lastname2) {
                    fullName = fullName.concat(" ").concat(person.lastname2);
                }
            }

            if (fullName.length <= 0) {
                fullName = "?"
            }

            return fullName;
        }
    },

    mounted () {
        this.hasRelatives = this.$slots.nested ? this.$slots.nested.length > 0 : false;
        this.relativesOpened = this.openedByDefault;

        if (this.hasRelatives && !this.showRelativesToggler) {
            this.relativesOpened = true;
        }
    }
}
</script>

<style lang="scss">
.phi-person-list-item{
    cursor: pointer;

    &.selected > .mu-item-wrapper div div.mu-item .mu-item-title, &.selected > .mu-item-wrapper div div.mu-item .mu-item-text{
        color: var(--phi-color-selected);
    }

    &.selected > .mu-item-wrapper{
        //background-color: #d5ebff;
    }

    .checked-avatar{
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        text-align: center;
        vertical-align: middle;
        padding: 5px;
        background-color: white;

        .mu-icon{
            color: var(--phi-color-selected);
        }
    }
}
</style>
