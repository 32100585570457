<script>
/*
Este componente recibe en v-model un arreglo de registros pertenecientes al Log de eventos de un nodo (e.g. /attendance/checkpoints):

[
    {
        "id": "xxy",
        "person": { objeto persona },
        "type": ...,
        "timestamp": ...,
        "data": ...
    },

    {
        "id": null,                     // Cuando el ID es nulo, se esta indicando que se espera que la persona se liste como "pendiente"
        "person": { objeto persona },
        "type": ...,
        "timestamp": ...,
        "data": ...
    },

    {
        "id": null,
        "person": { objeto persona },
        "type": "xyz",                 // Cuando el ID es nulo y hay un tipo presente, se espera que la persona tenga ese tipo (e.g. Se espera que pedro suba a la ruta)
        "timestamp": ...,
        "data": ...
    }
]
*/

import app from '../../../../store/app.js';

import PhiPersonPicker from '../../Person/Picker.vue';

import PhiTabs from '../../Tabs.vue';
import PhiTab from '../../Tab.vue';

import PhiPersonList from '../../Person/List.vue';
import PhiPersonListItem from '../../Person/ListItem.vue';

export default {
    components: {PhiPersonPicker, PhiTabs, PhiTab, PhiPersonList, PhiPersonListItem},

    props: {
        value: {
            type: Array,
            required: true
        },

        types: {
            type: Array,
            required: false
        }
    },

    data() {
        return {
            app,
            confirmDialog: false,
            deletingEntry: null,
            currentView: null,  // null = "pending"
        }
    },

    computed: {
        log: function() {
            return this.value.filter(entry => {
                entry.types = [];
                this.types.forEach(iType => {
                    if(entry.type == null || entry.type == iType.singular){
                        entry.types.push(iType);
                    }
                });

                if (!this.currentView) {
                    return !entry.id;
                }

                return entry.id && entry.type == this.currentView;
            });
        }
    },

    methods: {
        setCurrentView(newView) {
            this.currentView = newView;
        },

        confirmEntryDeletion(entry) {
            this.deletingEntry = entry;
            this.confirmDialog = true;
        },

        deleteEntry() {
            if(this.deletingEntry !== null){
                this.$emit("delete", this.deletingEntry);
            }
            this.confirmDialog = false;
        },

        addEntry(person, type) {
            this.$emit("add", {person: person.id, type});
        },

        selectPerson(person) {
            this.addEntry(person, this.currentView);
        },

        closeDialog(){
            this.deletingEntry = null;
            this.confirmDialog = false;
        }
    }
}
</script>


<template>
    <div class="phi-node-event-log">

        <phi-tabs v-model="currentView">
            <phi-tab
                :value="null"
                label="Pendiente"
            ></phi-tab>

            <phi-tab
                v-for="iType in types"
                :key="iType.singular"
                :value="iType.singular"
                :label="iType.plural"
            ></phi-tab>
        </phi-tabs>

        <div class="person-picker" v-if="!!currentView" >
            <phi-person-picker :api="app.api" label="buscar" @select="selectPerson"></phi-person-picker>
        </div>

        <phi-person-list>
            <phi-person-list-item
                v-for="(entry, index) in log"
                :key="index + entry.person.id"
                :person="entry.person"
            >
                <span slot="describe">
                    <div v-if="entry.id">
                        <span class="type">{{ entry.type }} </span>
                        <span class="timestamp">{{ $date(entry.timestamp, "time") }}</span>
                    </div>
                </span>

                <span slot="after" v-if="!entry.id && entry.types.length == 1">
                    &nbsp;
                </span>

                <template v-if="!entry.id && entry.types.length == 1">
                    <button class="right-button" slot="right" v-for="iType in entry.types" :key="iType.singular" @click="addEntry(entry.person, iType.singular)">{{iType.singular}}</button>
                </template>

                <mu-icon-menu icon="more_vert" slot="right" v-if="!entry.id && entry.types.length > 1">
                    <mu-menu-item v-for="iType in entry.types" :title="iType.singular" :key="iType.singular"  @click="addEntry(entry.person, iType.singular)"/>
                </mu-icon-menu>


                <mu-icon value="delete" @click="confirmEntryDeletion(entry)" v-if="entry.id" slot="right"></mu-icon>
            </phi-person-list-item>
        </phi-person-list>

        <mu-dialog :open="confirmDialog" @close="closeDialog">
            {{$t('question.deleteThisEvent')}}
            <mu-flat-button slot="actions" primary @click="deleteEntry" :label="$t('action.accept')"/>
            <mu-flat-button slot="actions" @click="closeDialog" :label="$t('action.cancel')"/>
        </mu-dialog>
    </div>
</template>


<style lang="scss">
.phi-node-event-log {

    .tab-container {
        .tab-list {
            list-style: none;
            margin: 0;
            padding: 0;

            display: flex;

            border-bottom: 1px solid #eee;

            li {
                flex: 1;
                text-align: center;
                opacity: .8;

                cursor: pointer;
                padding: 8px;
                background-color: rgba(0,0,0, .02);

                &.active {
                    font-weight: bold;
                    background-color: #fff;
                }
            }
        }
    }

    .person-picker {
        margin: 8px 12px;
        border-bottom: 1px dashed #ddd;
    }

    button.right-button{
        margin-right: 14px;
        padding: 4px 6px 4px 6px;
        background-color: rgba(0,0,0, .02);
        border: 1px solid #eee;
        border-radius: 4px;
    }
}

</style>