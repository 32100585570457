<template>
    <div class="post-notificator">

        <slot name="button" v-bind:openNotifications="openNotifications">
            <button @click="openNotifications">{{ $t('postNotificator.sendNotifications') }}</button>
        </slot>

        <!-- dialog -->
        <ui-dialog :open.sync="dialog">
            <template #contents>
                <slot v-if="isDone" name="done">
                    <div class="post-notificator__done">
                        <ui-icon size="60" value="mdi:email-check-outline"></ui-icon>
                        <p>{{ $t('postNotificator.notificationsSent') }}</p>
                    </div>
                </slot>

                <div v-else>
                    <div v-if="isSending">
                        <slot name="sending">
                            <ui-loading-line></ui-loading-line>
                        </slot>
                    </div>

                    <div v-else>
                        <ui-item
                            v-for="person in people"
                            :key="person.id"
                            :icon="audienceSelected.indexOf(person.id) >= 0 ? 'g:check' : person.avatar"
                            :text="person.firstName + ' ' + person.lastName"
                            @click="selectPerson(person.id)"
                        ></ui-item>
                    </div>
                </div>
            </template>

            <template #footer>
                <button
                    class="ui-button"
                    @click="sendNotifications"
                    v-if="!isDone && audienceSelected.length > 0"
                >
                    {{ $t('postNotificator.submit') }} {{ audienceSelected.length }} {{ audienceSelected.length == 1 ? $t('postNotificator.notification') : $t('postNotificator.notifications') }}
                </button>

                <button class="ui-button" @click="closeDialog()">{{ isDone ? $t('postNotificator.close') : $t('postNotificator.cancel') }}</button>
            </template>
        </ui-dialog>

    </div>
</template>

<script>
import useApi from '@/modules/api/mixins/useApi'
import useI18n from '@/modules/i18n/mixins/useI18n.js'
import postsApi from '@/modules/posts/api/posts'

import UiLoadingLine from '@/modules/ui/components/UiLoadingLine/UiLoadingLine.vue'

import { UiItem, UiDialog, UiIcon } from '@/modules/ui/components';

export default {
    name: 'post-notificator',
    mixins: [useApi,useI18n],
    api: postsApi,

    components: {UiLoadingLine, UiDialog, UiItem, UiIcon},

    props: {
        title: {
            type: String,
            default: null
        },

        type: {
            type: String,
            default: null
        },

        description: {
            type: String,
            default: null
        },

        people: {
            type: Array,
            default: () => []
        },

        notificationParents: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isSending: false,
            isDone: false,
            dialog: false,
            audienceSelected: this.people.map(person => String(person.id))
        }
    },

    methods: {
        selectPerson(person) {
            let result = this.audienceSelected.indexOf(person);

            if (result >= 0) {
                this.audienceSelected.splice(result, 1);
            } else {
                this.audienceSelected.push(person);
            }
        },

        openNotifications() {
            this.dialog = true;
        },

        closeDialog() {
            this.isSending = false;
            this.isDone    = false;
            this.dialog    = false;
        },

        async sendNotifications() {
            this.isSending = true;
            let audience = [];

            if (this.notificationParents) {
                audience = await this.customAudience(this.audienceSelected.join(","))
                audience = audience.concat(this.audienceSelected)
            } else {
                audience = this.audienceSelected
            }

            let newPost = {
                title: this.title,
                type: this.type,
                description: this.description,
                transport: true,
                audience: {
                    bcc: {
                        sourcePpl: audience,
                        source:null,
                        people: audience
                    }
                }
            };

            let response = await this.$api.createPost(this.$httpClient.payload.id, newPost);
            if (response) {
                this.isSending = false;
                this.isDone    = true;
                this.$emit('done', response);

                setTimeout(() => {
                   this.closeDialog();
                }, 4000);
            }
        },

        async customAudience(students) {
            let response = await this.$api.generateAudience(students);
            return response.map(item => item.id);
        }
    },

    watch: {
        dialog(value) {
            if (!value) this.closeDialog()
        },

        // se escucha el cambio para que vuelva a seleccionar la nueva audiencia y no se queden pegados datos
        people(value) {
            this.audienceSelected = value.map(person => String(person.id))
        }
    },    

    i18n: {
        "de": {
            "postNotificator.submit": "senden",

            "postNotificator.notification": "Benachrichtigung",
            "postNotificator.notifications": "Benachrichtigungen",

            "postNotificator.close": "hautnah",
            "postNotificator.cancel": "abbrechen",

            "postNotificator.notificationsSent": "Benachrichtigungen gesendet",
            "postNotificator.sendNotifications": "Benachrichtigungen senden",
        },

        "en": {
            "postNotificator.submit": "Submit",

            "postNotificator.notification": "notification",
            "postNotificator.notifications": "notifications",

            "postNotificator.close": "Close",
            "postNotificator.cancel": "Cancel",

            "postNotificator.notificationsSent": "Notifications sent",
            "postNotificator.sendNotifications": "Send notifications",
        },

        "es": {
            "postNotificator.submit": "Enviar",

            "postNotificator.notification": "notificación",
            "postNotificator.notifications": "notificaciones",

            "postNotificator.close": "cerrar",
            "postNotificator.cancel": "cancelar",

            "postNotificator.notificationsSent": "Notificaciones enviadas",
            "postNotificator.sendNotifications": "Enviar notificaciones",
        },

        "fr": {
            "postNotificator.submit": "envoyer",

            "postNotificator.notification": "notification",
            "postNotificator.notifications": "notifications",

            "postNotificator.close": "fermer",
            "postNotificator.cancel": "annuler",

            "postNotificator.notificationsSent": "notifications envoyées",
            "postNotificator.sendNotifications": "envoyer des notifications",
        },

        "it": {
            "postNotificator.submit": "send",

            "postNotificator.notification": "preavviso",
            "postNotificator.notifications": "notifiche",

            "postNotificator.close": "da vicino",
            "postNotificator.cancel": "annullare",

            "postNotificator.notificationsSent": "notifiche inviate",
            "postNotificator.sendNotifications": "inviare notifiche",
        }
    }
}
</script>

<style lang="scss" scoped>
.ui-loading-line {
    margin: 0 auto;
}

.post-notificator__done {
    width: 100%;
    text-align: center;
}
</style>